$(window).on("load",function(){
});
$(function(){

	var $body = $('body');
	
	//SVGスプライト
	$.ajax({
		type: 'get',
		url: '/wp/wp-content/themes/karatsu-torizen/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
	}).done(function(data) {
		var svg = $(data).find('svg');
		$('body').prepend(svg);
	});

	//ページ内スクロール
	$.smooziee();

	//開閉用ボタンをクリックでクラスの切替え
	$('.header_btn_sp').on('click', function () {
		$body.toggleClass('open');
		// bodyにopenがあれば
		if($($body).hasClass('open')){
			$('.header_sp_btn_text').html('CLOSE');
		} else {
			$('.header_sp_btn_text').html('MENU');
		}
		return false;
	});

	//メニュー名以外の部分をクリックで閉じる
	$('.layer').on('click', function () {
		$body.removeClass('open');
		$('.header_sp_btn_text').html('MENU');
	});

	//ヘッダーサブメニュー
	if(window.matchMedia("(max-width: 768px)").matches){
		$('.plus_btn').on('click', function () {
			if ($(this).hasClass('on')) {
				$(this).removeClass('on');
				$('.submenu_wrap').removeClass('on');
				$('.submenu_wrap').slideUp(300);
				$('.submenu_child').removeClass('on');
			} else {
				$(this).toggleClass('on');
				$('.submenu_wrap').toggleClass('on');
				$('.submenu_wrap').slideDown(300);
				$('.submenu_child').toggleClass('on');
			}
		});
	}else{ 
		$(".submenu").hover(
		function() {
			$(this)
				.find(".submenu_wrap")
				.stop(true)
				.slideDown(300);
		},
		function() {
			$(this)
				.find(".submenu_wrap")
				.slideUp(300);
		}
		);
	}

	//Youtubeサムネカスタマイズ
	$(function() {
		$(".movie-thumb").on("click", function(){
			videoControl("playVideo",$(this).prev("iframe"));
			$(this).hide();
		});
		function videoControl(action,tgt){
			var $playerWindow = $(tgt)[0].contentWindow;
			$playerWindow.postMessage('{"event":"command","func":"'+action+'","args":""}', '*');
		}
	});

});